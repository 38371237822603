import * as React from 'react'
import {t} from 'lib/translate'
import {connectMenu} from "react-instantsearch-dom";
import {isUndefined} from 'lodash'
import {Columns} from "react-bulma-components";

export interface PeriodOrdering {
    [k: string]: number
}

interface MainMenuProps {
    periodOrder: PeriodOrdering
}

function MenuColumn({children, title}: React.PropsWithChildren<{ title: string }>) {
    return <Columns.Column size="one-quarter">
        <div className="widget">
            <h6 className="widget-title uppercase">{title}</h6>
            {children}
        </div>
    </Columns.Column>
}

const TagCloud = connectMenu(({items, createURL, refine}) => {
    return <div className="tagcloud">
        {items.map(item => (
            <React.Fragment key={item.label}>
                <a rel="nofollow" onClick={event => {
                    event.preventDefault();
                    refine(item.value);
                }}
                   href={createURL(item.value)}
                   className={item.isRefined ? 'refined' : ''}>
                    {item.label}
                </a>
                {' '}
            </React.Fragment>
        ))}
    </div>
})

export default function HomeFilters({periodOrder = {}}: MainMenuProps) {
    // Translate gender values using localized labels
    const translateGender = (items) => items.map(item => ({...item, label: t(`costumes.show.${item.label}`)}))
    const periodComparer = makeComparer(periodOrder);

    return <Columns>
        <MenuColumn title={t('costumes.show.period')}>
            <TagCloud attribute='period' limit={30} transformItems={(items) => items.sort(periodComparer)}/>
        </MenuColumn>
        <MenuColumn title={t('costumes.show.category')}>
            <TagCloud attribute='collection' limit={30}/>
        </MenuColumn>
        <MenuColumn title={t('costumes.show.family')}>
            <TagCloud attribute='family' limit={30}/>
        </MenuColumn>
        <MenuColumn title={t('costumes.show.gender')}>
            <TagCloud attribute={'gender'} transformItems={translateGender}/>
        </MenuColumn>
    </Columns>
}

interface PeriodHit {
    label: string
}

/**
 * Returns a comparer for periods: Takes two hits for menu and return comparing function
 */
const makeComparer = (ordering: PeriodOrdering) => function (a: PeriodHit, b: PeriodHit): number {
    let idxA = ordering[a.label];
    let idxB = ordering[b.label];
    if (!isUndefined(idxA) && !isUndefined(idxB)) { // both orders defined
        // if they have same order sort alphabetically
        if (idxA === idxB) return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        else return idxA - idxB; // compare by defined orders
    } else if (!isUndefined(idxA)) {
        return -1; // only A has index
    } else if (!isUndefined(idxB)) {
        return 1; // only B has index
    } else {
        // compare by natural order case insensitive
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    }
};
