import {SearchState} from "../store/features/searchStateSlice";
import {debounce, pickBy, isEmpty} from "lodash";
import qs from 'qs';
import {useSelector} from "react-redux";
import {useState} from "react";

/**
 * Passed to instant search to create urls in a tags for menu
 * @param state
 */
export const createURL = (state: SearchState) => `?${qs.stringify(state)}`;

/**
 * Convert given search state to an url
 *
 * @param location
 * @param searchState
 */
const searchStateToUrl = ({location}: Window, searchState: SearchState) =>
    isEmpty(searchState) ? location.pathname : `${location.pathname}${createURL(searchState)}`;

/**
 * Debounced function that updates url in history when search changes
 */
export const saveSearchStateInUrl = debounce(({configure, menu, page, ...state}: SearchState) => {
    const filtered = {...state, menu: pickBy(menu), page: page != 1 ? page : null}
    const nonEmptyStateValues = pickBy(filtered, v => !isEmpty(v) || typeof v === 'number');
    window.history.pushState(nonEmptyStateValues, null, searchStateToUrl(window, nonEmptyStateValues));
}, 100)

type SearchQueryParams = SearchState & {
    source?: 'back'
}

/**
 * Convert url params to search state
 * @param search
 */
const urlToSearchState = ({search}: Location): SearchQueryParams =>
    qs.parse(search, {ignoreQueryPrefix: true});

/**
 * Custom hook used to return a search state variable initialized with custom logic
 * @param detail whether I'm in detail page (where state is loaded from redux)
 */
export function useSearchState(detail: boolean): SearchState {
    // We have two sources for initial search state: redux state and query string
    const reduxSearchState = useSelector<SearchState>(s => s.searchState)
    const querySearchState: SearchQueryParams = urlToSearchState(window.location);
    // Loaded search state for algolia, if in detail or coming back from search load from redux else from params
    const initialSearchState = (querySearchState.source == 'back' || detail) ? reduxSearchState : querySearchState;

    return useState<SearchState>(initialSearchState)
}

