import * as React from 'react'
import * as Fa from 'react-fontawesome';
import {connectPagination} from "react-instantsearch-dom";
import {range} from 'lodash'
import qs from 'qs';
import { useSelector } from 'react-redux';
import {RootState} from "store/reducers/rootReducer";

// How many pages to show around current page
const WIDTH = 4

const Pagination = ({currentRefinement, nbPages, refine}) => {
    const changePage = (page : number) => (e) => {
        e.preventDefault()
        refine(page)
    }
    const goToFirstPage = changePage(1)
    const goToLastPage = changePage(nbPages)
    const previousPage = changePage(currentRefinement - 1)
    const nextPage = changePage(currentRefinement + 1)
    const { menu, page: currentPage } = useSelector((state: RootState) => state.searchState)

    const buildUrl = (page: number) => {
        const newMenu = {...menu, page}
        return `${location.pathname}?${qs.stringify(newMenu)}`
    }

    const pages = range(Math.max(1, currentRefinement - WIDTH), 1 + Math.min(currentRefinement + WIDTH, nbPages))

    return <div id="pagination">
        <ul>
            <li className="prev">
                <a href={buildUrl(1)} onClick={goToFirstPage} rel="prev">
                    <Fa tag='i' name='angle-double-left'/>
                </a>
            </li>
            <li className="prev">
                <a href={buildUrl(currentRefinement - 1)} onClick={previousPage} rel="prev">
                    <Fa tag='i' name='angle-left'/>
                </a>
            </li>

            {pages.map((page) => {
                const current = currentRefinement === page

                if (current) {
                    return <li key={page} className={'page selected'}>
                        <span className={'current'}>{page}</span>
                    </li>
                } else {
                    return (
                        <li key={page} className="page">
                            <a href={buildUrl(page)} onClick={changePage(page)} rel={page > currentRefinement ? 'next' : 'prev'}>
                                {page}
                            </a>
                        </li>
                    );
                }
            })}

            <li className="next">
                <a href={buildUrl(nbPages)} onClick={goToLastPage} rel="next">
                    <Fa tag='i' name='angle-double-right'/>
                </a>
            </li>
            <li className="next">
                <a href={buildUrl(currentRefinement + 1)} onClick={nextPage} rel='next'>
                    <Fa tag='i' name='angle-right'/>
                </a>
            </li>
        </ul>
    </div>
}

export default connectPagination(Pagination);
